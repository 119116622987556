import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { state } from '../services/state';
import { api } from '../services/api';
import { format_phone, formatDate, formateDateStr, removeFromArray } from '../utils/utils';
import { css } from 'goober';
import { openSupportDlg } from './supportDlg';

const c_item = css`
	border: 1px solid black;
	border-radius: 5px;
	padding: 10px;
	&:hover {
		background: var(--row-hl-color);
	}
`;

function Inbox(p: {}) {
	let el: HTMLDivElement;

	const l = makeAutoObservable({});

	async function delete_item(n: INotification) {
		if (!confirm('Are you sure you want to delete this message?')) return;
		await api.delete_notification(n);
		removeFromArray(n, state.inbox);
	}

	function reply(n: INotification) {
		const r: INotification = {
			user_id: state.user.id,
			name: state.user.name,
			email: state.user.email,
			phone: state.user.phone,
			to_user_id: n.user_id,
			to_name: n.name,
			message: null
		};
		openSupportDlg(r);
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<h4 class="dlg-header nomargin">Inbox</h4>
				<div class="dlg-content gap10">
					{state.inbox.map((n) => (
						<div class={c_item}>
							<div class="row">
								{!n.to_user_id && <label>[To System Admin]</label>}
								<label>From ({formateDateStr(n.create_date)})</label>
								<div class="flex gap10">
									<span>{n.name}</span>
									<span>{n.email}</span>
									<span>{format_phone(n.phone)}</span>
								</div>
							</div>

							<div class="row">
								<label>Message</label>
								<div>{n.message}</div>
							</div>
							<div class="flex gap10">
								<span class="grow"></span>
								{n.user_id && <a onClick={(e) => reply(n)}>Reply</a>}
								<a class="danger" onClick={(e) => delete_item(n)}>
									Delete
								</a>
							</div>
						</div>
					))}
				</div>
				<div class="dlg-footer">
					<button id="bt_inbox_close" onClick={(e) => dlg.close(el)}>
						Close
					</button>
				</div>
			</div>
		</div>
	);
}

export function openInbox(): Promise<void> {
	return dlg.open(Inbox, {});
}
