import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { api, PendingActionType } from '../services/api';
import { format_phone, removeFromArray, validate_phone } from '../utils/utils';
import { state } from '../services/state';
import { openClientInfoDlg } from './clientInfoDlg';
import { openActionDlg } from './actionDlg';
import { autofocus } from '../utils/autofocus';
import { openToast } from './toast';
import { ClientActions } from '../components/client_actions';

function ClientDlg(p: { client_id: number }) {
	let el: HTMLDivElement;

	let l = makeAutoObservable({
		client: {
			name: null,
			email: null,
			phone: null,
			provider: false,
			author_id: state.user.id
		} as IUser,
		actions: [] as IAction[],
		data: [] as IClientData[],
		is_new: !p.client_id,
		can_edit: !p.client_id
	});

	if (p.client_id) {
		api.get_user({ id: p.client_id }).then((r) => {
			l.client = r;
			l.can_edit = r.author_id === state.user.id;
		});
		api.get_actions({ client_id: p.client_id }).then((r) => {
			l.actions = r;
		});
		api.get_client_data(p.client_id).then((r) => {
			l.data = r;
		});
	}

	async function add_client_data() {
		if (l.is_new) {
			await save(false);
			if (l.is_new) return;
		}

		let info: IClientData = {
			client_id: p.client_id,
			user_id: state.user.id,
			org_id: state.user_org?.id,
			name: null,
			data: null
		};

		await openClientInfoDlg(info);
		if (info.id) l.data.push(info);
	}

	async function add_action() {
		if (l.is_new) {
			await save(false);
			if (l.is_new) return;
		}
		let action: IAction = {
			post_id: null,
			user_id: state.user.id,
			client_id: l.client.id,
			info: '',
			type: PendingActionType,
			resource: null,
			pending: true,
			complete: false,
			amount: null,
			action_date: new Date().toISOString(),
			org_id: state.user_org?.id || null
		};
		await openActionDlg(action);
		if (action.id) l.actions.push(action);
	}

	async function save(close: boolean) {
		if (!l.client.name || !l.client.phone) {
			openToast('Name and Phone are required', true);
			return;
		}
		if (!validate_phone(l.client.phone)) {
			openToast('Phone must contain 10 digits', true);
			return;
		}
		await api.save_user(l.client);
		l.is_new = false;
		if (close) dlg.close(el, l.client);
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ 'min-width': '500px' }}>
				<h4 class="dlg-header nomargin">Client Info</h4>
				<div class="dlg-content">
					<div class="row">
						<label>Name</label>
						<input
							disabled={!l.can_edit}
							value={l.client.name}
							onChange={(e) => (l.client.name = e.target.value)}
							ref={(e) => autofocus(e)}
						/>
					</div>
					<div class="row">
						<label>Phone</label>
						<input
							disabled={!l.can_edit}
							value={format_phone(l.client.phone)}
							onChange={(e) => (l.client.phone = e.target.value)}
						/>
					</div>
					{l.data.map((d) => (
						<div class="row">
							<label style={{ display: 'flex' }}>
								<span class="grow">
									{d.name} ({state.user_org?.name})
								</span>
								<a
									onClick={async (e) => {
										await openClientInfoDlg(d);
										if (!d.id) removeFromArray(d, l.data);
									}}
								>
									Edit
								</a>
							</label>
							<div class="valuebox">{d.data}</div>
						</div>
					))}
					<ClientActions actions={l.actions} />
				</div>
				<div class="dlg-footer">
					{state.is_provider && (
						<button id="bt_client_dlg_add_info" onClick={add_client_data}>
							Add Info
						</button>
					)}
					{state.is_provider && (
						<button id="bt_client_dlg_add_action" onClick={add_action}>
							Add Action
						</button>
					)}
					<span class="grow" />
					{l.can_edit && (
						<button id="bt_client_dlg_save" class="primary" onClick={(e) => save(true)}>
							Save
						</button>
					)}
					<button id="bt_client_dlg_close" onClick={(e) => dlg.close(el)}>
						{l.can_edit ? 'Cancel' : 'Close'}
					</button>
				</div>
			</div>
		</div>
	);
}

export function openClientDlg(client_id: number): Promise<IUser> {
	return dlg.open(ClientDlg, { client_id });
}
