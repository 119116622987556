import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { api } from '../services/api';
import { state } from '../services/state';
import { autofocus } from '../utils/autofocus';
import { openAccountDlg } from './accountDlg';
import { openToast } from './toast';
import { raw_phone_number, format_phone } from '../utils/utils';

function LoginDlg(p: {}) {
	let el: HTMLDivElement;
	let l = makeAutoObservable({
		email_or_phone: null as string,
		pin: null as string,
		unknown_user: false,
		user: null as IUser
	});

	async function sign_in() {
		const r = await api.sign_in({ email_or_phone: l.email_or_phone, pin: l.pin });
		if (r.error) {
			openToast(r.error, true);
			return;
		} else if (r.user) {
			state.sign_in(r);
			dlg.close(el);
		}
	}

	async function create_account() {
		let new_user: IUser = {
			email: null,
			phone: null,
			provider: true,
			name: null,
			pin: null
		};
		dlg.close(el);
		await openAccountDlg(new_user);
		if (new_user.id) {
			const r = await api.sign_in({ email_or_phone: new_user.email || new_user.phone, pin: new_user.pin });
			state.sign_in(r);
		}
	}

	async function reset_pin() {
		if (!l.email_or_phone) {
			openToast('Enter email or phone first', true);
			return;
		}
		api.reset_pin(l.email_or_phone);
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '300px' }}>
				<div class="dlg-header">Sign In</div>
				<div class="dlg-content">
					<div class="row">
						<label>Email or Phone number</label>
						<input
							type="text"
							autocomplete="username"
							value={l.email_or_phone}
							onInput={(e) => {
								l.email_or_phone = e.target.value?.toLowerCase().trim() || null;
							}}
							ref={(e) => [autofocus(e)]}
						/>
					</div>

					<div class="row">
						<label>PIN</label>
						<input
							type="password"
							value={l.pin}
							onKeyPress={(e) => {
								if (e.key === 'Enter') sign_in();
							}}
							onInput={(e) => (l.pin = e.target.value)}
						/>
					</div>
					<div class="row flex center">
						<a id="bt_login_dlg_reset_pin" class="grow" onClick={(e) => reset_pin()}>
							Reset PIN
						</a>
						<button id="bt_login_dlg_signin" class="primary" onClick={sign_in}>
							Sign In
						</button>
					</div>
				</div>
				<div class="dlg-footer">
					<a id="bt_login_dlg_newaccount" onClick={(e) => create_account()}>
						Create new account
					</a>
					<span class="grow" />
					<button id="bt_login_dlg_cancel" onClick={(e) => dlg.close(el)}>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
}

export function openLoginDlg() {
	return dlg.open(LoginDlg, {});
}
