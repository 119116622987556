import { dlg } from '../dlg';

function MessageDlg(p: { message: string }) {
	let el: HTMLDivElement = null;
	return (
		<div class="dlgbg" ref={el} onClick={(e) => dlg.close(el)}>
			<div class="dlg">
				<div class="dlg-content prewrap">{p.message}</div>
				<div class="dlg-footer">
					<button id="bt_message_close" onClick={(e) => dlg.close(el)}>
						Close
					</button>
				</div>
			</div>
		</div>
	);
}

export function showMessage(message: string) {
	return dlg.open(MessageDlg, { message });
}
