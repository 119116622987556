import { dlg } from '../dlg';
import { PostTag } from '../components/posting';
import { state } from '../services/state';
import { PostType, PostTypeMap } from './postingDlg';
import { openActionDlg } from './actionDlg';
import { openClientDlg } from './clientDlg';
import { formateDateStr, format_phone, removeFromArray, get_loc_desc } from '../utils/utils';
import { api, PendingActionType } from '../services/api';
import { makeAutoObservable } from 'mobx';
import { ClientActions } from '../components/client_actions';

function ViewPostDlg(z: { p: IPost }) {
	let el: HTMLDivElement;
	const p = z.p;
	const l = makeAutoObservable({ actions: [] as IAction[] });
	api.get_actions({ post_id: p.id }).then((r) => (l.actions = r));

	let help_offered = p.type === PostType.help_offered;
	let can_edit = false;
	if (p.user_id == state.user?.id) {
		can_edit = true;
	} else if (state.is_org_user && state.user_org.id === p.id) {
		can_edit = true;
	}

	async function edit_posting() {
		dlg.close(el);
		state.editPost(p.id);
	}

	async function edit_action(action: IAction) {
		let is_new = false;
		if (!action) {
			is_new = true;
			action = {
				post_id: p.id,
				user_id: state.user.id,
				client_id: p.user_id,
				info: '',
				type: PendingActionType,
				resource: null,
				pending: true,
				complete: false,
				amount: null,
				action_date: new Date().toISOString(),
				org_id: state.user_org?.id || null
			};
		}

		await openActionDlg(action);
		if (is_new) {
			if (action.id) l.actions.push(action);
		} else if (!action.id) {
			removeFromArray(action, l.actions);
		}
	}

	let can_action = PostTypeMap.get(p.type).can_action;

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<div class="dlg-header center">
					<PostTag t={p.type} />
					<div class="grow" style={{}}>
						{p.title}
					</div>
					{formateDateStr(p.post_date)}
				</div>
				<div class="dlg-content">
					<div class="row">
						<label>Description</label>
						<div class="valuebox">{p.description}</div>
					</div>
					{p.org && (
						<div class="row">
							<label>Organization</label>
							<div class="valuebox">{p.org.name}</div>
						</div>
					)}
					{(state.is_provider || !can_action) && (
						<div class="row">
							<label>Name</label>
							<div class="valuebox">{p.name}</div>
						</div>
					)}
					{(state.is_provider || !can_action) && (
						<div class="row">
							<label>Phone Number</label>
							<div class="valuebox">{format_phone(p.phone)}</div>
						</div>
					)}
					<div class="row">
						<label>Location</label>
						<div class="valuebox">{get_loc_desc(p.location)}</div>
					</div>
					{p.tags.length && (
						<div class="row">
							<label>Tags</label>
							<div class="valuebox">{p.tags.join(', ')}</div>
						</div>
					)}
					{l.actions.length && state.is_provider && <ClientActions actions={l.actions} />}
					{p.expire_date && (
						<div class="row">
							<label>Expiration: {formateDateStr(p.expire_date)}</label>
							{/* <div class="valuebox">{formateDateStr(l.expire_date)}</div> */}
						</div>
					)}
				</div>
				<div class="dlg-footer">
					{!state.has_user && <span style={{ color: 'royalblue' }}>Sign in for contact info</span>}
					{state.is_provider && can_action && (
						<button
							id="bt_view_post_client_info"
							onClick={(e) => {
								openClientDlg(p.user_id);
								dlg.close(el);
							}}
						>
							Client Info
						</button>
					)}
					{can_edit && (
						<button id="bt_view_post_edit" onclick={edit_posting}>
							Edit
						</button>
					)}
					<span class="grow"></span>
					{state.is_provider && can_action && (
						<button id="bt_view_post_add_action" onClick={(e) => edit_action(null)} class="primary">
							Add Action
						</button>
					)}
					<button id="bt_view_post_close" onClick={(e) => dlg.close(el)}>
						Back
					</button>
				</div>
			</div>
		</div>
	);
}

export function openViewPostDlg(p: IPost): Promise<IPost> {
	return dlg.open(ViewPostDlg, { p });
}
