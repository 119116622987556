import { makeAutoObservable } from 'mobx';
import { api } from './api';
import { geo, get_loc_rect } from './geoService';
import { openPostingDlg, PostType } from '../dialogs/postingDlg';
import { openAboutDlg } from '../dialogs/aboutDlg';
import { openGuidelines } from '../dialogs/guidelines';
import { config } from './config';

class State {
	page = null as string;
	show_user_bar = true;
	mobile = false;
	posts: IPost[] = [];
	local_orgs: IOrg[] = [];
	user: IUser = null;
	dark = false;

	user_org: IOrg = null;
	user_orgs: IOrg[] = [];

	inbox: INotification[] = [];
	system: ISystem = { client_tags: [], post_tags: [] };
	tags: string[] = [
		'clothing',
		'shelter',
		'housing',
		'rent',
		'utility bills',
		'food',
		'transportation',
		'job',
		'consulting'
	].sort();
	filter = {
		type: null as PostType,
		tag: null as string
	};

	constructor() {
		makeAutoObservable(this);
	}

	get loading() {
		return !geo.loc;
	}

	get sys_admin() {
		return this.user?.sys_admin === true;
	}

	get is_provider() {
		return this.user?.provider || false;
	}

	get has_user() {
		return !!this.user;
	}

	get is_org_user() {
		return !!this.user_org;
	}

	get is_org_admin() {
		return true; // TODO
	}

	get f_posts() {
		let r = this.posts;
		if (this.filter.type) r = r.filter((p) => p.type === this.filter.type);
		if (this.filter.tag) r = r.filter((p) => p.tags.includes(this.filter.tag));
		return r;
	}

	get f_local_orgs() {
		let r = this.local_orgs;
		if (this.filter.type && this.filter.type !== PostType.org_info) return [];
		if (this.filter.tag) r = r.filter((p) => p.tags.includes(this.filter.tag));
		return r;
	}

	set_theme(toggle: boolean) {
		if (toggle) state.dark = !state.dark;
		const theme = state.dark ? 'dark' : 'light';
		localStorage.setItem('theme', theme);
		// const theme = prefersDarkMode.matches ? 'dark' : 'light'
		document.documentElement.setAttribute('data-theme', theme);
	}

	async sign_in(f: ISignInResult) {
		if (!f.user) return;

		this.user_org = null;
		this.user_orgs = [];
		this.user = f.user;
		config.session_id = f.session_id;
		this.inbox = f.inbox || [];

		localStorage.setItem('session_id', f.session_id);
		if (f.user.provider) {
			this.user_orgs = f.orgs || [];
			if (f.user.provider) {
				if (f.org_context) {
					this.set_org(f.org_context);
				}
			}
		} else {
			this.user_orgs = [];
			this.set_org(null);
		}
	}

	set_org(c: IOrgContext) {
		this.user_org = (c && state.user_orgs.find((o) => o.id === c.id)) || null;
		if (this.user_org) {
			localStorage.setItem('org_id', c.id.toString());
		} else {
			localStorage.removeItem('org_id');
		}
	}

	signOut() {
		this.user = null;
		this.user_org = null;
		this.user_orgs = [];
		config.session_id = null;
		localStorage.removeItem('session_id');
	}

	async init() {
		this.dark = localStorage.getItem('theme') === 'dark';
		this.set_theme(false);

		await config.init();
		let bb: IBeginInfo = { sign_in: null, geo_rect: null, geo_info: null };

		bb.geo_info = await geo.init();
		if (bb.geo_info) {
			bb.geo_rect = get_loc_rect(geo.range_miles, bb.geo_info.lat, bb.geo_info.lng);
		}

		let session_id = localStorage.getItem('session_id');
		if (session_id) {
			bb.sign_in = { session_id, org_id: parseInt(localStorage.getItem('org_id')) || null };
		}

		const b = await api.begin(bb);
		if (b.sign_in) {
			this.sign_in(b.sign_in);
		}

		geo.set_location(b.location);
		this.posts = b.local_posts;
		this.local_orgs = b.local_orgs;

		let new_user = localStorage.getItem('found') == null;
		if (new_user) {
			await openAboutDlg();
			await openGuidelines();
			localStorage.setItem('found', 'true');
		}
	}

	/*async auto_login() {
		let user_id = parseInt(localStorage.getItem('user_id'));
		if (user_id) {
			let user = await api.get_user({ id: user_id });
			if (user) {
				this.signIn(user);
			}
		}
	}*/

	async editPost(id: number) {
		const t = await api.get_post(id);
		if (t) {
			await openPostingDlg(t);
			this.refreshList();
		} else {
			alert('Post not found');
		}
		this.reset_url();
	}

	reset_url() {
		window.history.replaceState(null, null, document.location.origin);
	}

	async getLocalOrgs() {
		this.local_orgs = await api.get_orgs({ geo_rect: geo.rect });
		let badges = await api.get_badges({ org_ids: this.local_orgs.map((p) => p.id) });
		for (let o of this.local_orgs) {
			o.badges = badges.filter((a) => a.org_id === o.id);
		}
	}

	async getLocalPosts() {
		this.posts = await api.get_posts({ geo_rect: geo.rect });
	}

	async refreshList() {
		this.getLocalOrgs();
		this.getLocalPosts();

		/*if (this.page_org) {
			this.org_posts = await api.get_posts({ org_id: this.page_org.id });
			for (let p of this.org_posts) {
				let idx = this.posts.findIndex((pp) => pp.id === p.id);
				if (idx >= 0) this.posts.splice(idx, 1);
			}
		}*/
	}

	makeid(): string {
		let result = '';
		const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < 10) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
	}
}

export const state: State = new State();
state.init();

//@ts-ignore
window.state = state;
