import { makeAutoObservable } from 'mobx';
import { raw_phone_number } from '../utils/utils';
import { make_req } from './api2';
import { config } from './config';

export const PendingActionType = 'Pending';

class SupabaseAPI {
	constructor() {
		makeAutoObservable(this);
	}

	init() {}

	// orgs
	async save_org(org: IOrg) {
		let change: IOrg = {
			id: org.id,
			name: org.name,
			website: org.website,
			phone: org.phone,
			address: org.address,
			path: org.path,
			description: org.description,
			tags: org.tags,
			share_policy: org.share_policy,
			loc_id: org.loc_id,
			verified: org.verified
		};
		return make_req('save_org', change, org);
	}

	async get_org(f: IOrgFilter) {
		let r = await this.get_orgs(f);
		return r[0] || null;
	}

	async get_orgs(f: IOrgFilter): Promise<IOrg[]> {
		return make_req('get_orgs', f);
	}

	async delete_org(org: IOrg): Promise<void> {
		return make_req('delete_org', org);
	}

	// users
	async get_user(f: IUserFilter) {
		let r = await this.get_users(f);
		return r[0] || null;
	}

	async resources(org_id: number): Promise<string[]> {
		return make_req('resources', org_id);
	}

	async save_user(user: IUser) {
		let change: IUser = {
			id: user.id,
			name: user.name,
			email: user.email,
			phone: raw_phone_number(user.phone).toString(),
			pin: user.pin,
			provider: user.provider,
			author_id: user.author_id
		};
		return make_req('save_user', change, user);
	}

	async save_user_pin(user: IUser) {
		return make_req('save_user_pin', { id: user.id, pin: user.pin });
	}

	async get_users(f: IUserFilter): Promise<IUser[]> {
		return make_req('get_users', f);
	}

	// actions
	async save_action(n: IAction) {
		let change: IAction = {
			id: n.id,
			user_id: n.user_id,
			org_id: n.org_id,
			client_id: n.client_id,
			post_id: n.post_id,
			action_date: n.action_date,
			info: n.info,
			amount: n.amount,
			type: n.type,
			pending: n.pending,
			complete: n.complete,
			resource: n.resource
		};
		return make_req('save_action', change, n);
	}

	async get_actions(f: IActionFilter): Promise<IAction[]> {
		return make_req('get_actions', f);
	}

	async delete_action(a: IAction): Promise<void> {
		return make_req('delete_action', a);
	}

	// postings
	async save_post(post: IPost) {
		let change: IPost = {
			id: post.id,
			user_id: post.user_id,
			org_id: post.org_id,
			name: post.name,
			phone: post.phone,
			title: post.title,
			description: post.description,
			post_date: post.post_date,
			expire_date: post.expire_date,
			type: post.type,
			archived: post.archived,
			update_date: post.update_date,
			tags: post.tags,
			loc_id: post.loc_id
		};
		return make_req('save_post', change, post);
	}

	async get_post(id: number) {
		let r = await this.get_posts({ ids: [id] });
		return r[0] || null;
	}

	async get_posts(f: IPostFilter): Promise<IPost[]> {
		return make_req('get_posts', f);
	}

	async delete_post(p: IPost): Promise<void> {
		return make_req('delete_post', p);
	}

	// system
	async get_system(): Promise<ISystem> {
		return make_req('get_system');
	}

	// notifications
	async save_notification(n: INotification) {
		let change: INotification = { ...n };
		delete change.to_name;
		return make_req('save_notification', change, n);
	}

	async get_notifications(user_id: number): Promise<INotification[]> {
		return make_req('get_notifications', user_id);
	}

	async delete_notification(n: INotification): Promise<void> {
		return make_req('delete_notification', n);
	}

	// user_orgs

	async get_org_users(org_id: number): Promise<IOrgUser[]> {
		return make_req('get_org_users', org_id);
	}

	async save_org_user(org_user: IOrgUser) {
		let change: IOrgUser = {
			id: org_user.id,
			user_id: org_user.user_id,
			org_id: org_user.org_id,
			admin: org_user.admin
		};
		return make_req('save_org_user', change, org_user);
	}

	async delete_org_user(org_user: IOrgUser) {
		return make_req('delete_org_user', org_user);
	}

	// org_orgs
	async get_sharing_orgs(org_id: number): Promise<IOrgOrg[]> {
		return make_req('get_sharing_orgs', org_id);
	}

	async save_org_org(oo: IOrgOrg) {
		let change: IOrgOrg = {
			id: oo.id,
			sub_org_id: oo.sub_org_id,
			org_id: oo.org_id
		};
		return make_req('save_org_org', change, oo);
	}

	async delete_org_org(oo: IOrgOrg) {
		return make_req('delete_org_org', oo);
	}

	// client_data
	async get_client_data(client_id: number): Promise<IClientData[]> {
		return make_req('get_client_data', client_id);
	}

	async save_client_data(oo: IClientData) {
		let change: IClientData = {
			id: oo.id,
			user_id: oo.user_id,
			org_id: oo.org_id,
			client_id: oo.client_id,
			name: oo.name,
			data: oo.data
		};
		return make_req('save_client_data', change, oo);
	}

	async delete_client_data(oo: IClientData) {
		return make_req('delete_client_data', oo);
	}

	// badges
	async create_badge(b: IBadge): Promise<void> {
		let z = { ...b };
		delete z.org;
		return make_req('create_badge', z, b);
	}

	async get_badges(f: { org_id?: number; org_ids?: number[] }): Promise<IBadge[]> {
		return make_req('get_badges', f);
	}

	async delete_badge(b: IBadge) {
		return make_req('delete_badge', b);
	}

	// location
	async create_location(r: IGeoInfo): Promise<ILocation> {
		return make_req('create_location', r);
	}

	async get_locations(f: { zip_code?: string; geo_rect?: IGeoRect }): Promise<ILocation[]> {
		return make_req('get_locations', f);
	}

	// misc
	get_org_context(org_id: number): Promise<IOrgContext> {
		return make_req('get_org_context', org_id);
	}

	sign_in(f: ISignIn): Promise<ISignInResult> {
		return make_req('sign_in', f);
	}

	reset_pin(email_or_phone: string) {
		make_req('reset_pin', email_or_phone);
	}

	begin(f: IBeginInfo): Promise<IBegin> {
		return make_req('begin', f);
	}
}
export const api = new SupabaseAPI();

//@ts-ignore
window.api = api;
