import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { api } from '../services/api';
import { For } from 'solid-js';
import { debounce, format_phone, validate_phone } from '../utils/utils';
import { autofocus } from '../utils/autofocus';
import { openClientDlg } from './clientDlg';
import { coming_soon } from '../components/coming_soon';

function ClientListDlg(p: {}) {
	let el: HTMLDivElement;

	let l = makeAutoObservable({
		clients: [] as IUser[],
		name: null as string,
		phone: null as string,
		total: 0
	});

	const search = debounce(_do_search, 500);

	function _do_search() {
		let f: IUserFilter = { like_name: null, phone: null, client_only: true };
		if (l.name?.length > 2) f.like_name = l.name;
		else if (validate_phone(l.phone)) f.phone = l.phone;
		if (f.like_name || f.phone) {
			api.get_users(f).then((r) => {
				l.clients = r;
			});
		}
	}

	async function add_client() {
		let c = await openClientDlg(null);
		if (c?.id) l.clients.push(c);
	}

	async function view_client(c: IUser) {
		let cc = await openClientDlg(c.id);
		Object.assign(c, cc);
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg">
				<h4 class="dlg-header nomargin">Clients</h4>
				<div class="dlg-content">
					<div class="flex gap20 center" style={{ 'margin-bottom': '10px' }}>
						<div>
							<label>Name&nbsp;</label>
							<input
								ref={(e) => autofocus(e)}
								value={l.name}
								onInput={(e) => {
									l.name = e.target.value;
									search();
								}}
							/>
						</div>
						<div>
							<label>Phone&nbsp;</label>
							<input
								value={l.phone}
								onInput={(e) => {
									l.phone = e.target.value;
									search();
								}}
							/>
						</div>
					</div>
					<table class="data_table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Phone</th>
								<th>Email</th>
								<th>Address</th>
								<th>Tags</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<For
								each={l.clients}
								fallback={
									<tr>
										<td colSpan={6}>No Records Found</td>
									</tr>
								}
							>
								{(c) => (
									<tr>
										<td>{c.name}</td>
										<td>{format_phone(c.phone)}</td>
										<td>{c.email}</td>
										<td>{}</td>
										<td>{}</td>
										<td>
											<a onClick={(e) => view_client(c)}>View</a>
										</td>
									</tr>
								)}
							</For>
						</tbody>
					</table>
				</div>
				<div class="dlg-footer">
					<button id="bt_client_list_add" onClick={add_client}>
						Add Client
					</button>
					<span class="grow" />
					<button onClick={coming_soon}>Download (csv)</button>
					<button id="bt_client_list_close" onClick={(e) => dlg.close(el)}>
						Close
					</button>
				</div>
			</div>
		</div>
	);
}

export function openClientListDlg() {
	dlg.open(ClientListDlg, {});
}
