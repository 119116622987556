import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { api } from '../services/api';
import { state } from '../services/state';
import { autofocus } from '../utils/autofocus';
import { openToast } from './toast';
import { raw_phone_number } from '../utils/utils';

function AccountDlg(p: { user: IUser }) {
	let el: HTMLDivElement;
	let l = makeAutoObservable({
		u: { ...p.user } as IUser,
		new_user: !p.user.id
	});
	const u = l.u;

	async function save() {
		if (l.new_user) {
			if (!u.pin || u.pin.length < 4) {
				openToast('PIN must be 4 digits', true);
				return;
			}
		}

		const rawnum = raw_phone_number(u.phone)?.toString();
		if (rawnum.length !== 10) {
			openToast('Phone number must have 10 digits', true);
			return;
		}

		if (!u.email) {
			openToast('Email is required', true);
			return;
		}

		await api.save_user(u);
		Object.assign(p.user, u);
		dlg.close(el);
	}

	function change_pin() {
		let r = prompt('Enter new pin (1-5 digits)');
		if (r) {
			p.user.pin = u.pin = r;
			api.save_user_pin(u);
		}
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<div class="dlg-header">{l.new_user ? 'Create Provider Account' : 'Edit Account'}</div>
				<div class="dlg-content">
					<div class="row">
						<label>Name</label>
						<input value={u.name} onChange={(e) => (u.name = e.target.value)} ref={(e) => [autofocus(e)]} />
					</div>

					<div class="row">
						<label>Email</label>
						<input
							value={u.email}
							onInput={(e) => {
								u.email = e.target.value;
								u.email = u.email.toLowerCase().trim();
							}}
						/>
					</div>

					<div class="row">
						<label>Phone</label>
						<input value={u.phone} onChange={(e) => (u.phone = e.target.value)} />
					</div>

					{l.new_user && (
						<div class="row">
							<label>PIN (4 digit code)</label>
							<input value={u.pin} onChange={(e) => (u.pin = e.target.value)} />
						</div>
					)}

					{/* <div class="row">
						<label>
							<input type="checkbox" checked={u.provider} onchange={(e) => (u.provider = !u.provider)} />
							&nbsp;Provider
						</label>
					</div> */}
					{!l.new_user && (
						<div class="row">
							<a onClick={change_pin}>change PIN</a>
						</div>
					)}
				</div>
				<div class="dlg-footer">
					<button id="bt_account_dlg_save" class="primary" onClick={save}>
						{l.new_user ? 'Create Account' : 'Save'}
					</button>
					<button id="bt_account_dlg_cancel" onClick={(e) => dlg.close(el)}>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
}

export function openAccountDlg(user: IUser) {
	return dlg.open(AccountDlg, { user });
}
